import React, { useState, useEffect } from "react";
import axios from "axios";

const TopGameList = ({ limit }) => {
  const [games, setGames] = useState([]);
  const [overlayColors, setOverlayColors] = useState({}); // Store overlay colors
  const [loading, setLoading] = useState(true);

  const apiJSONURL = process.env.REACT_APP_API_JSON_URL;

  const standardColors = [
    { name: "Red", rgb: [255, 0, 0] },
    { name: "Green", rgb: [0, 255, 0] },
    { name: "Blue", rgb: [0, 0, 255] },
    { name: "Yellow", rgb: [255, 255, 0] },
    { name: "Purple", rgb: [128, 0, 128] },
  ];

  const fallbackColor = { name: "Fallback", rgb: [142, 176, 0] }; // #8EB000 as fallback

  useEffect(() => {
    const fetchTopGames = async () => {
      try {
        const response = await axios.post(
          apiJSONURL,
          `cmd=getTopGames&domain=sg8`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const jsonData = response.data;

        let transformedData = {};

        for (let key in jsonData) {
          if (jsonData.hasOwnProperty(key)) {
            let splitValues = jsonData[key]
              .split("|")
              .map((value) => value.trim());
            let gameImg;
            if (splitValues[3]) {
              gameImg =
                splitValues[0] === "PGSOFT" ||
                splitValues[0] === "NEXTSPIN" ||
                splitValues[0] === "BOOONGO" ||
                splitValues[0] === "PLAYSON"
                  ? `https://sg8.casino/json/resources/images/WLP/${splitValues[3].slice(
                      0,
                      2
                    )}/${splitValues[3]}.jpg`
                  : `https://sg8.casino/json/resources/images/WLP/${splitValues[3].slice(
                      0,
                      2
                    )}/200x200/${splitValues[3]}.jpg`;
            }
            transformedData[key] = {
              provider: splitValues[0],
              gameId: splitValues[2],
              gameName: splitValues[1],
              gameIdN: splitValues[3],
              gameImg: gameImg,
            };
          }
        }

        setGames(transformedData);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch games:", error);
        setLoading(false);
      }
    };
    fetchTopGames();
  }, []);

  const calculateClosestColor = (dominantColor) => {
    let closestColor = null;
    let minDistance = Infinity;

    standardColors.forEach((color) => {
      const distance = Math.sqrt(
        Math.pow(dominantColor[0] - color.rgb[0], 2) +
        Math.pow(dominantColor[1] - color.rgb[1], 2) +
        Math.pow(dominantColor[2] - color.rgb[2], 2)
      );
      if (distance < minDistance) {
        minDistance = distance;
        closestColor = color;
      }
    });

    return closestColor;
  };

  const handleImageLoad = async (gameId, event) => {
    if (overlayColors[gameId]) {
      // If the color is already calculated, skip recalculation
      return;
    }

    const img = event.target;

    try {
      const Vibrant = await import("node-vibrant");

      const vibrant = new Vibrant.default(img);
      const palette = await vibrant.getPalette();
      const dominantColor = palette.Vibrant?.rgb || palette.Muted?.rgb;

      let selectedColor;
      if (dominantColor) {
        selectedColor = calculateClosestColor(dominantColor);
      } else {
        // If dominant color is unavailable, use fallback
        selectedColor = fallbackColor;
      }

      // Store the selected color in the state
      setOverlayColors((prevColors) => ({
        ...prevColors,
        [gameId]: selectedColor,
      }));
    } catch (error) {
      console.error("Error extracting color:", error);

      // Use fallback color in case of an error
      setOverlayColors((prevColors) => ({
        ...prevColors,
        [gameId]: fallbackColor,
      }));
    }
  };

  const launchGame = async (game) => {
    const tokenURL = process.env.REACT_APP_TOKEN_URL;
    const launchURL = process.env.REACT_APP_HTTPS_LAUNCH_URL;

    try {
      const username = localStorage.getItem("username");
      const encryptedUsername = encodeURIComponent(
        localStorage.getItem("encryptedUsername")
      );

      const tokenResponse = await axios.get(
        `${tokenURL}?u=${encryptedUsername}&g=${game.gameIdN}&t=transfer&m=mobile`
      );
      const sessionToken = tokenResponse.data.token;

      const lang = "en";
      const mobileIndicator = window.innerWidth <= 840 ? "1" : "";
      const url = `${launchURL}?s=${sessionToken}&l=${lang}&m=${mobileIndicator}`;

      window.open(
        url,
        "_blank",
        "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600"
      );
    } catch (error) {
      console.error("Failed to launch game:", error);
    }
  };

  if (loading) {
    return (
      <section className="loadingGames">
        <img
          src="/images/sg8.gif"
          style={{ width: "200px" }}
          alt="Loading..."
        />
      </section>
    );
  }

  return (
    <div className="gameListWrapper">
      {games ? (
        Object.keys(games)
          .slice(0, limit)
          .map((key, index) => {
            const game = games[key];
            const overlayColor = overlayColors[game.gameIdN];
            const gradient =
              overlayColor &&
              `linear-gradient(to top, rgba(${overlayColor.rgb[0]}, ${overlayColor.rgb[1]}, ${overlayColor.rgb[2]}, 1) 0%, rgba(0, 0, 0, 0) 100%)`;

            return (
              <div key={index} className="gameWrapper">
                <div className="game">
                  <div className="rank-circle">{index + 1}</div>
                  <div
                    className="gameImg"
                    style={{
                      position: "relative",
                      width: "200px",
                      height: "200px",
                    }}
                  >
                    <img
                      alt={`${game.gameName} ${game.provider}`}
                      src={game.gameImg}
                      onLoad={(event) => handleImageLoad(game.gameIdN, event)}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <div
                      className="overlay"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        backgroundImage: gradient || "none",
                      }}
                    ></div>
                  </div>
                  <div className="gameDetails">
                    <div className="gameName">{game.gameName}</div>
                    <button onClick={() => launchGame(game)}>Play Now</button>
                  </div>
                </div>
              </div>
            );
          })
      ) : (
        <section className="loadingGames">
          <div>
            <p>No games found.</p>
          </div>
        </section>
      )}
    </div>
  );
};

export default TopGameList;
